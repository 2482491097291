// @ts-nocheck
import React from "react";
import { TableCell, Typography, IconButton, Box } from "@material-ui/core";
import { timeConverter } from "../../utils/validations";
import CustumMenus from "../../partials/CustumMenus";
import UnfoldMoreIcon from "@material-ui/icons/UnfoldMore";
import EditContractId from "../../partials/modal/EditContractId";
import { firstLetterUpperCase } from "../../utils/validations";

export const generateGridData = (result) => {
  const gridColumns = [
    {
      icon: (name, handlerSort) => {
        const defaultValue = "company_name";
        const descValue = "company_name desc";
        return (
          <Box display="flex">
            <Typography>{name}</Typography>
            <IconButton
              color="primary"
              onClick={() => handlerSort(defaultValue, descValue)}
            >
              <UnfoldMoreIcon />
            </IconButton>
          </Box>
        );
      },
      name: "Company",
      alignPosition: "center",
      CellRenderer: (value) => {
        const companyName = value.company_name;
        return (
          <TableCell
            align="center"
            style={{
              color: value.status === "expired" && "rgba(255,0,0, 0.7)",
            }}
          >
            <Typography>{companyName}</Typography>
          </TableCell>
        );
      },
    },
    {
      name: "Company Contract",
      alignPosition: "center",
      CellRenderer: (value) => {
        const first_name = value.company_owner_first_name;
        const last_name = value.company_owner_last_name;
        return (
          <TableCell
            align="center"
            style={{
              color: value.status === "expired" && "rgba(255,0,0, 0.7)",
            }}
          >
            <Typography>{first_name}</Typography>
            <Typography>{last_name}</Typography>
          </TableCell>
        );
      },
    },
    {
      name: "Key",
      alignPosition: "center",
      CellRenderer: (value) => {
        const key = value.id;
        return (
          <TableCell
            align="center"
            style={{
              color: value.status === "expired" && "rgba(255,0,0, 0.7)",
            }}
          >
            <Typography>{key}</Typography>
          </TableCell>
        );
      },
    },
    {
      name: "Contract ID",
      alignPosition: "center",
      CellRenderer: (value, handler) => {
        const contractId = value.contract_id;
        return (
          <TableCell align="center">
            <Box display="flex" justifyContent="space-evenly">
              <Typography
                style={{
                  color: value.status === "expired" && "rgba(255,0,0, 0.7)",
                }}
              >
                {contractId}
              </Typography>
              <EditContractId
                id={value.id}
                handler={handler}
                valueId={value.contract_id}
              />
            </Box>
          </TableCell>
        );
      },
    },
    {
      name: "Channels",
      alignPosition: "center",
      CellRenderer: (value) => {
        const cameras = value.cameras_count;
        return (
          <TableCell
            align="center"
            style={{
              color: value.status === "expired" && "rgba(255,0,0, 0.7)",
            }}
          >
            <Typography>{cameras}</Typography>
          </TableCell>
        );
      },
    },
    {
      name: "Days",
      alignPosition: "center",
      CellRenderer: (value) => {
        const days = value.days;
        return (
          <TableCell
            align="center"
            style={{
              color: value.status === "expired" && "rgba(255,0,0, 0.7)",
            }}
          >
            <Typography>{days}</Typography>
          </TableCell>
        );
      },
    },
    {
      icon: (name, handlerSort) => {
        const defaultValue = "activated_at";
        const descValue = "activated_at desc";
        return (
          <Box display="flex">
            <Typography>{name}</Typography>
            <IconButton
              color="primary"
              onClick={() => handlerSort(defaultValue, descValue)}
            >
              <UnfoldMoreIcon />
            </IconButton>
          </Box>
        );
      },
      name: "Activation Date",
      alignPosition: "center",
      CellRenderer: (value) => {
        const activatedAt = value.activated_at;
        return (
          <TableCell
            align="center"
            style={{
              color: value.status === "expired" && "rgba(255,0,0, 0.7)",
            }}
          >
            <Typography>
              {activatedAt !== null ? timeConverter(activatedAt) : ""}
            </Typography>
          </TableCell>
        );
      },
    },
    {
      icon: (name, handlerSort) => {
        const defaultValue = "expires_at";
        const descValue = "expires_at desc";
        return (
          <Box display="flex">
            <Typography>{name}</Typography>
            <IconButton
              color="primary"
              onClick={() => handlerSort(defaultValue, descValue)}
            >
              <UnfoldMoreIcon />
            </IconButton>
          </Box>
        );
      },
      name: "Expiration Date",
      alignPosition: "center",
      CellRenderer: (value) => {
        const expiresAt = value.expires_at;
        return (
          <TableCell
            align="center"
            style={{
              color: value.status === "expired" && "rgba(255,0,0, 0.7)",
            }}
          >
            <Typography>
              {expiresAt !== null ? timeConverter(expiresAt) : ""}
            </Typography>
          </TableCell>
        );
      },
    },
    {
      name: "Days Left",
      alignPosition: "center",
      CellRenderer: (value) => {
        const daysLeft = value.days_left;
        return (
          <TableCell
            align="center"
            style={{
              color: value.status === "expired" && "rgba(255,0,0, 0.7)",
            }}
          >
            <Typography>{daysLeft}</Typography>
          </TableCell>
        );
      },
    },
    {
      name: "Account Manager",
      alignPosition: "center",
      CellRenderer: (value) => {
        const email = value.company_owner_email;
        return (
          <TableCell
            align="center"
            style={{
              color: value.status === "expired" && "rgba(255,0,0, 0.7)",
            }}
          >
            <Typography>{email}</Typography>
          </TableCell>
        );
      },
    },
    {
      name: "Type",
      alignPosition: "center",
      CellRenderer: (value) => {
        const type = value.trial;
        return (
          <TableCell
            align="center"
            style={{
              color: value.status === "expired" && "rgba(255,0,0, 0.7)",
            }}
          >
            <Typography>{type ? "Demo" : "Regular"}</Typography>
          </TableCell>
        );
      },
    },
    {
      name: "Status",
      alignPosition: "center",
      CellRenderer: (value) => {
        const status = firstLetterUpperCase(value.status);
        return (
          <TableCell
            align="center"
            style={{
              color: value.status === "expired" && "rgba(255,0,0, 0.7)",
            }}
          >
            <Typography>{status}</Typography>
          </TableCell>
        );
      },
    },

    {
      name: "Action",
      alignPosition: "center",
      CellRenderer: (_, rowData, handlerEnableChange) => {
        return (
          <TableCell align="center">
            <CustumMenus
              element={rowData}
              handlerEnableChange={handlerEnableChange}
            />
          </TableCell>
        );
      },
    },

    // {
    //   name: "Days",
    //   alignPosition: "center",
    //   CellRenderer: (value) => {
    //     const days = value.days;
    //     return (
    //       <TableCell align="center">
    //         <Typography>{days}</Typography>
    //       </TableCell>
    //     );
    //   },
    // },

    // {
    //   name: "Company Owner",
    //   alignPosition: "center",
    //   CellRenderer: (value) => {
    //     const { company_owner_first_name, company_owner_last_name } = value;
    //     return (
    //       <TableCell align="center">
    //         <Typography>
    //           {company_owner_first_name !== null &&
    //           company_owner_last_name !== null
    //             ? `${company_owner_first_name} ${company_owner_last_name}`
    //             : ""}
    //         </Typography>
    //       </TableCell>
    //     );
    //   },
    // },

    // {
    //   name: "Installed at",
    //   alignPosition: "center",
    //   CellRenderer: (value) => {
    //     const installationName = value.installation_name;
    //     return (
    //       <TableCell align="center">
    //         <Typography>{installationName}</Typography>
    //       </TableCell>
    //     );
    //   },
    // },
  ];

  const gridRows = result;

  return {
    gridColumns,
    gridRows,
  };
};
